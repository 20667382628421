:root {
    --primary: #FF9800;
    --color-warning: #FF9800;
    --tag-free-color: #FF9800;
    --tag-pro-color: #60C92F;
    --tag-adv-color: #25B58A;
}

.tailwind-custom {
.text-center {
    text-align: center !important;
  }
  .font-normal {
    font-weight: 400 !important;
  }
  
  .font-bold {
    font-weight: 700 !important;
  }
  
  .grid {
    display: grid !important;
  }
  
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  
  /* Padding */
  .mb-1 {
    margin-bottom: 0.25rem !important; /* 4px */
  }
  .mt-2 {
    margin-top: 0.5rem !important;
  }
  .ml-2 {
    margin-left: 0.5rem !important;
  }
  .mr-2 {
    margin-right: 0.5rem !important;
  }
  .pl-2 {
    padding-left: 0.5rem !important;
  }
  .pt-3 {
    padding-top: 0.75rem !important; /* 12px */
  }
  
  .pb-3 {
    padding-bottom: 0.75rem !important; /* 12px */
  }
  .mt-3 {
    margin-top: 0.75rem !important;
  }
  .mb-4 {
    margin-bottom: 1rem !important ; /* 4px */
  }
  .mt-4 {
    margin-top: 1rem !important;
  }
  .mr-4 {
    margin-right: 1rem !important;
  }
  .ml-4 {
    margin-left: 1rem !important;
  }
  .mb-4 {
    margin-bottom: 1rem !important;
  }
  .pb-4 {
    padding-bottom: 1rem !important; /* 4px */
  }
  .mt-6 {
    margin-top: 1.5rem !important ; /* 4px */
  }
  .mr-6 {
    margin-right: 1.5rem !important ; /* 4px */
  }
  .ml-6 {
    margin-left: 1.5rem !important ; /* 4px */
  }
  .pb-6 {
    padding-bottom: 1.5rem !important ; /* 4px */
  }
  .mt-8 {
    margin-top: 2rem !important;
  }
  .mr-8 {
    margin-right: 2rem !important;
  }
  .ml-8 {
    margin-left: 2rem !important;
  }
  .mb-8 {
    margin-bottom: 2rem !important;
  }
  .mt-12 {
    margin-top: 3rem !important;
  }
  .mb-12 {
    margin-bottom: 3rem !important; /* 48px */
  }
  .pt-12 {
    padding-top: 3rem !important; /* 48px */
  }
  .pb-12 {
      padding-bottom: 3rem !important; /* 48px */
  }
  .mb-20 {
    margin-bottom: 5rem !important; /* 80px */
  }
  
  /* Flexbox */
  .flex {
    display: flex !important;
  }
  .items-center {
    align-items: center !important;
  }
  
  .justify-center {
    justify-content: center !important;
  }
  .justify-end {
    justify-content: flex-end;
  }
  /* Width */
  .w-full {
    width: 100% !important;
  }
  .w-2\/4 {
    width: 50% !important;
  }
  
  .w-1\/4 {
    width: 25% !important;
  }
  
  .w-1\/4 {
    width: 25% !important;
  }
  .w-1\/3 {
    width: 33.333333%;
  }
  .text-white {
    color: rgb(255 255 255) !important;
  }
  
  /* Font Size */
  .text-xs {
    font-size: 0.75rem !important; /* 12px */
    line-height: 1rem !important; /* 16px */
  }
  
  .text-sm {
    font-size: 0.875rem !important; /* 14px */
    line-height: 1.25rem !important; /* 20px */
  }
  
  .text-base {
    font-size: 1rem !important; /* 16px */
    line-height: 1.5rem !important; /* 24px */
  }
  
  .text-lg {
    font-size: 1.125rem !important; /* 18px */
    line-height: 1.75rem !important; /* 28px */
  }
  
  .text-xl {
    font-size: 1.25rem !important; /* 20px */
    line-height: 1.75rem !important; /* 28px */
  }
  
  .text-2xl {
    font-size: 1.5rem !important; /* 24px */
    line-height: 2rem !important; /* 32px */
  }
  
  .text-3xl {
    font-size: 1.875rem !important; /* 30px */
    line-height: 2.25rem !important; /* 36px */
  }
  
  .text-4xl {
    font-size: 2.25rem !important; /* 36px */
    line-height: 2.5rem !important; /* 40px */
  }
  
  .text-5xl {
    font-size: 3rem !important; /* 48px */
    line-height: 1 !important;
  }
  
  .text-6xl {
    font-size: 3.75rem !important; /* 60px */
    line-height: 1 !important;
  }
  
  .text-7xl {
    font-size: 4.5rem !important; /* 72px */
    line-height: 1 !important;
  }
  
  .text-8xl {
    font-size: 6rem !important; /* 96px */
    line-height: 1 !important;
  }
  
  .text-9xl {
    font-size: 8rem !important; /* 128px */
    line-height: 1 !important;
  }
  /* cursor */
  .cursor-pointer {
      cursor: pointer !important;
  }
  
  /* Pointer Events */
  .pointer-events-none	{
      pointer-events: none !important;
  }
  /* Text color */ 
  .text-gray-500 {
      color: rgb(107 114 128) !important;
  }  
}

.btn-circle {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
  border-radius: 50% !important;
}

.btn-default-size {
  min-width: 100px;
  min-height: 40px;
}